import React, { useRef } from "react";
import { createRoot } from 'react-dom/client';
import ErrorBox from "../../common/ErrorBox";
import TableComponent from "./TableComponent";
import { useState, useEffect } from "react";
import { ConfigProvider, Modal, App } from 'antd';
import { EditableContextProvider } from "../../common/EditableContextProvider";
import FormComponent from "./FormComponent";
import { customTheme } from "../../common/customTheme";
import useVentas from "./useVentas";
import useClientes from "../clientes/useClientes";
import ShowItems from '../ventas/modals/ShowItems';
import AnularFactura from "./modals/AnularFactura";

export default function Main() {
    const {
        editItem, fillItems, items, selectItem, newItem, saveItem, destroyItem,
        loading, tableParams, searchNit, fillResources: fillVentasResources, resources: VentasResources,
        validateNit, revertir, pagos, fechas, updateFecha
    } = useVentas();
    const { fillResources, resources } = useClientes();

    const [formVisible, setFormVisible] = useState(false);
    const { modal, notification } = App.useApp();

    const modalActions = useRef();
    const delModalActions = useRef();
    const formikRef = useRef();

    useEffect(() => {
        Promise.all([
            fillResources(),
            fillVentasResources(),
            fillItems(),
        ]);
    }, []);

    const handleTableChange = (pagination, filters, sorter, extra) => {
        let params = {
            ...tableParams,
            pagination,
            filters,
            sorter,
        };
        fillItems(params);
    }
    const handleFormAction = async (item) => {

        return new Promise(async (resolve, reject) => {
            try {
                if (item === editItem) { notification.info({ description: "No hay cambios." }); resolve(); return; }
                let v_id = await saveItem(item);
                pagos.hidePagosModal();
                hideForm();
                notification.success({ description: "¡Guardado correcto!" });
                window.open(`/factura/pdfv2/${v_id}`, "_blank", "noreferrer");
                resolve();
            } catch (e) {
                if (e.code === "SERVER_ERROR") {
                    notification.error({ description: e.message });
                }
                reject(e);
            }
        });
    }
    const handleDeleteItem = (id) => {
        delModalActions.current.show(id);
    }

    const handleRevertir = (id) => {
        Modal.confirm({
            title: "Confirmar",
            content: "¿Desea revertir la anulación?",
            onOk: async () => {
                await revertir(id);
            }
        });
    }

    const addNewItem = () => { newItem(); showForm(); }
    const setUpdateItem = (item) => { selectItem(item); showForm(); }
    const showForm = () => { setFormVisible(true); }
    const hideForm = () => { setFormVisible(false); }
    const setFormikRef = (formik) => { formikRef.current = formik; }

    return (
        <div>
            <div className='my-1 py-1 row'>
                <div className="col-auto">
                    <button className="btn btn-sm btn-green"
                        onClick={() => { addNewItem(); }}
                    >
                        <i className="fa-solid fa-plus me-1"></i>
                        Agregar Nueva Venta
                    </button>
                </div>
                <div className="col-md-2"></div>

                <div className="col-md-auto">
                    <span>De:</span>
                </div>
                <div className="col-md-2">
                    <input type="date" className="form-control form-control-sm"
                        value={fechas.fecha_inicio}
                        onChange={(e) => updateFecha('fecha_inicio', e.target.value)}
                    />
                </div>
                <div className="col-md-auto">
                    <span>hasta:</span>
                </div>
                <div className="col-md-2">
                    <input type="date" className="form-control form-control-sm"
                        value={fechas.fecha_fin}
                        onChange={(e) => updateFecha('fecha_fin', e.target.value)}
                    />
                </div>
                <div className="col-md-auto">
                    <button className="btn btn-sm btn-light border px-3"
                        onClick={() => fillItems()}
                    >
                        Buscar
                    </button>
                </div>
            </div>
            <TableComponent
                items={items}
                isLoading={loading}
                tableParams={tableParams}
                onTableChange={handleTableChange}
                updateItem={setUpdateItem}
                deleteItem={handleDeleteItem}
                showItems={modalActions.current?.show}
                revertir={handleRevertir}
                puntosVenta={VentasResources.puntosVenta}
            />

            <Modal title="Formulario"
                open={formVisible}
                onCancel={hideForm}
                destroyOnClose={true}
                footer={null}
                width={1200}
                centered
            >
                <EditableContextProvider data={{
                    editItem: editItem,
                    onFormAction: handleFormAction,
                    resources,
                    searchNit,
                    puntosVenta: VentasResources.puntosVenta,
                    validateNit,
                    pagos
                }}
                >

                    <FormComponent />
                </EditableContextProvider>
            </Modal>

            <ShowItems>
                {(showItems) => {modalActions.current = showItems}}
            </ShowItems>

            <AnularFactura
                motivosAnulacion={VentasResources.motivosAnulacion}
                destroyItem={destroyItem}
            >
                {(e) => {delModalActions.current = e}}
            </AnularFactura>
        </div>
    );
}

let main = document.getElementById('ventas-main');
if (main) {
    const root = createRoot(main);
    root.render(
        <React.StrictMode>
            <ErrorBox>
                <ConfigProvider theme={customTheme}>
                    <App>
                        <Main />
                    </App>
                </ConfigProvider>
            </ErrorBox>
        </React.StrictMode>
    );
}
